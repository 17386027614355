@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* These two classdefs are necessary for animating route transitions */
  .animated-switch {
    @apply relative h-full w-full overflow-hidden;
  }

  .animated-switch > div {
    @apply absolute h-full w-full overflow-auto;
  }
}
